<template>
  <loader v-if="showloader == true"></loader>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <!-- <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid w-450px ps-15"
            placeholder="Recherche"
            @change="searchdata"
            v-model="searchValue"
          />
        </div> -->
      </div>
      <div class="card-toolbar">
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!-- <button type="button" class="btn btn-light-primary me-3">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button> -->
          <button
            type="button"
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_Langue"
            style="float: right"
            @click="maj('', 'ajouter')"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Ajouter
          </button>
          
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="
                  fw-bold
                  fs-5
                  text-gray-800
                  border-bottom-2 border-gray-200
                  text-uppercase
                "
              >
                <th>#</th>
                <th>
                  Name
                </th>
               <th>Code</th>
              <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.code }}</td>  
                <td class="end">
                  <a
                    href="#"
                    @click="maj(item, 'editer')"
                      style="cursor: pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_Langue"
                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="media/icons/duotune/art/art005.svg" />
                    </span>
                  </a>
                </td>              
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
 
  <div class="modal fade" tabindex="-1" id="kt_modal_Langue">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ formMode }} Langue</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetDataQuizz()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
              <div class="col-md-12 padding_left_none">
                <label for="name" class="form-label required">Nom </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control m-2"
                  v-model="add_data.name"
                />
              </div>
          </div>
          <div class="row">
              <div class="col-md-12 padding_left_none">
                <label for="name" class="form-label required">Code </label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  class="form-control m-2"
                  v-model="add_data.code"
                />
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetData()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="submit()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ApiService } from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import loader from "../utility/loader.vue";
export default {
  components: {
    loader,
  },
  data() {
    return {
      data:{},
      add_data: {},
      formMode: "ajouter",
      showloader: false,
    };
  },
  methods: {
    maj(item, val) {
      this.formMode = val;
      this.selectOffre = [];
      if (val == "editer") {
        const data_tmp = JSON.parse(JSON.stringify(item));
        this.add_data = data_tmp;
      } else {
        this.add_data = {};
      }
    },
    submit(){
      this.showloader = true;
      this.add_data["formMode"] = this.formMode;
      ApiService.setHeader();
      ApiService.post("traduction/langue/InsertOrUpdate", this.add_data)
        .then((response) => {
          this.showloader = false;
          this.alertSucess();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    getListeLangue(){
      ApiService.setHeader();
      ApiService.get("traduction/langue/All")
        .then((response) => {
          if(response.data){
            this.data = response.data
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    alertSucess() {
      Swal.fire({
        text: "Enregistrer avec succes!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        location.reload();
      });
    },
  },
  mounted() {
    this.getListeLangue();
  },
};
</script>